








































































































import Vue from "vue";
import _ from "lodash";

import Requester from "@/requester";

import { AuthLoginFormData } from "../../../back/src/types/formData";

export default Vue.extend({
  name: "Login",
  data: () => ({
    loading: {
      submitForm: false,
    },
    showPassword: false,
    form: {
      email: null,
      senha: null,
    },
    rules: {
      required: [(v: string) => !!v || ""],
    },
  }),
  computed: {
    appendIconPassword() {
      return this.showPassword ? "mdi-eye-off" : "mdi-eye";
    },
    formAuthLogin(): Vue & { validate: () => boolean } {
      return this.$refs.formAuthLogin as Vue & {
        validate: () => boolean;
      };
    },
  },
  methods: {
    submitFormDebounce: _.debounce(async function () {
      await this.submitForm();
    }, 200),
    async submitForm() {
      if (!this.formAuthLogin.validate()) {
        return;
      }
      this.loading.submitForm = true;
      const formData: AuthLoginFormData = {
        email: this.form.email,
        senha: this.form.senha,
      };
      try {
        const user = await Requester.auth.public.login(formData);
        this.$store.dispatch("login", user);
        this.$root.$emit("showToast", {
          text: "Seja bem vindo!",
          color: "success",
        });
        this.$router.push({ name: "Home" });
      } catch (err) {
        this.$root.$emit("showToast", {
          text: err.response?.data ?? err.toString(),
          color: "error",
        });
      } finally {
        this.loading.submitForm = false;
      }
    },
  },
});
